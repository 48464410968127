








import { Component, Prop, Vue } from 'vue-property-decorator'
import CloseCircleIcon from '@/app/ui/assets/close_circle.vue'
import ChecklistCircleIcon from '@/app/ui/assets/filled_checklist_circle.vue'

@Component({
  components: {
    CloseCircleIcon,
    ChecklistCircleIcon,
  },
})
export default class RoleItem extends Vue {
  @Prop({ default: false }) private isChecked!: boolean
  @Prop({ default: '', required: true }) text!: string
}
