















































































import dayjs from 'dayjs'
import { Component, Vue } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/UserManagementController'
import Button from '@/app/ui/components/Button/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import RoleItem from '../components/RoleItem/index.vue'
import { AccessMenu } from '@/domain/entities/Account'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'

@Component({
  components: {
    Button,
    EditIcon,
    RoleItem,
    Modal,
    LoadingOverlay,
  },
})
export default class UserManagementDetail extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  confirmationModal = false
  successModal = false

  created() {
    this.fetchUserDetail()

    EventBus.$on(EventBusConstants.DELETE_ACCOUNT_SUCCESS, () => {
      this.successModal = true
    })
  }

  get userMenuList() {
    const groups: string[] = []
    for (let i = 0; i < controller.accessMenuData.length; i++) {
      if (!groups.includes(`${controller.accessMenuData[i].group}`)) {
        groups.push(`${controller.accessMenuData[i].group}`)
      }
    }

    const menuList = groups.map(item =>
      Object.assign(
        {},
        {
          group: item,
          data: controller.accessMenuData
            .filter(m => m.group === item)
            .map(menu => {
              return new AccessMenu(
                menu.id,
                menu.slug,
                menu.name,
                menu.group,
                !!(
                  controller.userDetail.accessMenus || [new AccessMenu()]
                ).find(v => v.slug === menu.slug)
              )
            }),
        }
      )
    )

    return menuList
  }

  private onDeleteAccount() {
    controller.deleteAccount(this.$route.params.id)
    this.confirmationModal = false
  }

  private fetchUserDetail() {
    controller.getMenus()
    controller.getUser(this.$route.params.id)
  }

  private onCloseSuccessModal() {
    this.successModal = false
    this.$router.push({ name: 'UserManagementPage' })
  }

  private getRoleName(role: string) {
    return Utils.roleName(role)
  }

  private formatDate(date: string) {
    return dayjs(date).format('DD MMMM YYYY[\n]HH:mm Z')
  }

  beforeDestroy() {
    EventBus.$off(EventBusConstants.DELETE_ACCOUNT_SUCCESS)
  }
}
